<template>
    <div class="home">
        <div class="titleContainer">
            <div class="titleText">
                <h1 class="title">matter combines ...</h1>
                <p><span class="human">by <a href="https://awwsmith.com" target="_blank">Andrew W. Smith</a></span> <span class="AI">and several AIs</span> <span class="entangled">for <a href="https://voidspacezine.com/" target="_blank">Voidspace's</a> Crash This Prose</span></p>
            </div>
            <div class="nodes">
                <p class="AI">8388608 nodes generated</p>
                <p class="AI">2896.309375740099</p>
                <p class="AI">53.81737057623773</p>
                <p class="AI">7.33603234563737</p>
            </div>
        </div>
        <div class="headerContainer">
            <div class="words">
                <p class="human">Select up to seven words</p>
            </div>
            <div class="selections">
                <p class="AI">{{inputs}}/7</p>
            </div>
            <div class="questions">
                <p class="human">?</p>
            </div>
        </div>
        <div class="pageContainer">
            <div class="crashText">
                <p class="AI" @click="select('At')">At</p>
                <p class="AI" @click="select('Covet')">Covet</p>
                <p class="AI" @click="select('An')">An</p>
                <p class="AI" @click="select('Argument')">Argument</p>
                <p class="AI" @click="select('Goal')">Goal</p>
                <p class="AI" @click="select('Notable')">Notable</p>
                <p class="AI" @click="select('Annihilate')">Annihilate</p>
                <p class="AI" @click="select('Wide')">Wide</p>
                <p class="AI" @click="select('Aboard')">Aboard</p>
                <p class="AI" @click="select('Balloon')">Balloon</p>
                <p class="AI" @click="select('Productive')">Productive</p>
                <p class="AI" @click="select('Opening')">Opening</p>
                <p class="AI" @click="select('A')">A</p>
                <p class="AI" @click="select('Trust')">Trust</p>
            </div>
            <div class="result">
                <div v-for="input in result" :key="input" v-html="input" class="results"></div>
            </div>
            <div class="questions">
                <p class="human" v-if="this.inputs >=1">How have these AI come to imagine these words in these ways?</p>
                <p class="human" v-if="this.inputs >=3">Do you accept this artificial intelligence as your partner, collaborator, judge, guide, teacher, therapist, pet, boss, lover...</p>
                <p class="human" v-if="this.inputs >=5">Do you want to combine with the machine?</p>
                <p class="human" v-if="this.inputs >=7">Do you trust your computer, or are you just tired?</p>
            </div>
        </div>
        <div class="footerContainer">
            <router-link :to="{name: 'HumanAbout'}">
                <h3 class="human">About this Project</h3>
            </router-link>
            <h3 class="entangled">{{ footerWord }}</h3>
            <router-link :to="{name: 'AIAbout'}">
                <h3 class="AI">About this Project</h3>
            </router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Home',
        data() {
            return {
                inputs: 0,
                result: [],
                footerWord: "Entangle",
                footerArray: ["Entangle", "Mix", "Blend", "Intertwine", "Meld", "Interweave", "Combine", "Intermingle", "Twist", "Entwine", "Unite", "Merge", "Amalgamate"],
                selection: {
                    At: ["<p>>>At</p><p>What is solar energy in this country?</p>", "<p>>>At</p><p>The next part of this prophecy is, that all the nations, and every being in every place of life, may believe in the immortality of God which he is a partaker of, if they are able to stand still.</p>", "<p>>>At</p><p>at, and a black man, I guess.</p>"],
                    Covet: ["<p>>>Covet</p><img src='https://awikhigan.irlh.org/gen/wl/?id=BYU5aBcfBEuQH88JENbTgxZROhwto8hi' alt='Covet'>", "<p>>>Covet</p><img src='https://awikhigan.irlh.org/gen/wl/?id=j3w3RkLaQFXEWdqKBfAp6OPhpUoCKPvs' alt='Covet'>", "<p>>>Covet</p><img src='https://awikhigan.irlh.org/gen/wl/?id=sBLjf6svI1J0GqcfG73FxR7zPZ9dMktp' alt='Covet'>"],
                    An: ["<p>>>An</p><p>an and he was really really excited and really strong.</p>", "<p>>>An</p><p>There's no right way to show that, and there is no surefire way to do that.</p>", "<p>>>An</p><p>She has no plans to move.</p>"],
                    Argument: ["<p>>>Argument</p><p>Argument: The best way to understand the difference between a human and a machine is to think about the difference between a human and a baby.</p>", "<p>>>Argument</p><p>Argument: The government is not a party to the contract.</p>", "<p>>>Argument</p><p>Argument: The above argument is based on the assumption that the person who is the subject of the argument is the one who is in the wrong. This is not always the case</p>"],
                    Goal: ["<p>>>Goal</p><p>Goal\" : { \"nameTag\" : \"Game\", \"url\" : \"/shop/durren_guitar/Guitar_Liver_Mood\", \"level\" : 4, \"type\" : \"CLASSIC\" } }, { \"catentry_id\" : \"10883028\", \"channelAvailability\" : \"BuyableInstoreAndOnline\", \"inventoryQuantity\" : \"2.0\", \"isSpecialOrderable\" : \"true\", \"soType\" : 'SO', \"soDeliveryDate\" : \"12/21/2018 - 12/25/2018\", \"quickShipAvailable\" : \"true\",</p>", "<p>>>Goal</p><p>Each user can create an individual role based on information from a group set of files, or in some cases an online group of persons or entities.</p>", "<p>>>Goal</p><p>You are the one in control, and the one to know when you should change.</p>"],
                    Notable: ["<p>>>Notable</p><p>Notable of any discussion of my article: How to write an excellent web application.</p>", "<p>>>Notable</p><p>The most interesting of these two is the one that gets the most attention.</p>", "<p>>>Notable</p><p>Notable to many of those he's encountered, I'm not sure he was really a member of the group, perhaps the whole thing just made him feel so much more in control of his actions after meeting him.</p>"],
                    Annihilate: ["<p>>>Annihilate</p><p>annihilate his minions and finish off your opponents</p>", "<p>>>Annihilate</p><p>A great way to go, and for many years, I have loved, and this is what made me happy.</p>", "<p>>>Annihilate</p><p>Dodge the impact of a massive attack, as long as you have the stamina!</p>"],
                    Wide: ["<p>>>Wide</p><p>Wide to the North, but on the east.</p>", "<p>>>Wide</p><p>The feature is available on Google Chrome and Google Apps.</p>", "<p>>>Wide</p><p>If you think these lamps are too dim you can easily find an inexpensive diming lamp in your local market.</p>"],
                    Aboard: ["<p>>>Aboard</p><img src='https://awikhigan.irlh.org/gen/wl/?id=PTCN1D5OYFf5qePFy6YmmcRWS95463an' alt='Aboard' class='imgsize'>", "<p>>>Aboard</p><img src='https://awikhigan.irlh.org/gen/wl/?id=Tp6hp9ByCiok0TaTGAX6caUD2Zorqqhd' alt='Aboard'>", "<p>>>Aboard</p><img src='https://awikhigan.irlh.org/gen/wl/?id=2NMa5pJO9C4gMfaM5jvG5iWQbj0hyKrw' alt='Aboard'>"],
                    Balloon: ["<p>>>Balloon</p><p>The country with the longest beaches in the world has no real coastline. The only place one can be sure of finding a place to run is on the ocean, or in the middle of nowhere.</p>", "<p>>>Balloon</p><p>Boom! At what point does it be necessary to know about what's going to happen when the New York Times releases this latest news?</p>", "<p>>>Balloon</p><p>I would play through a lot and I was the only one who didn't know it'd never happen to me back then.</p>"],
                    Productive: ["<p>>>Productive</p><img src='https://awikhigan.irlh.org/gen/wl/?id=lGTYgrxL7W6ICvQfpQmg0GEPhcNnW2LK' alt='Productive'>", "<p>>>Productive</p><img src='https://awikhigan.irlh.org/gen/wl/?id=MVi23FzaZyahpkKsdJvf14bhyfa9a1K2' alt='Productive'>", "<p>>>Productive</p><img src='https://awikhigan.irlh.org/gen/wl/?id=DMjqMnq5Lsk0hxKc78rvRX4RUcXIv8Ul' alt='Productive'>"],
                    Opening: ["<p>>>Opening</p><p>Opening the vault!</p>", "<p>>>Opening</p><p>Opening the door, he saw a young woman standing in the hallway.\n" +
                    "She was wearing a pink dress and a pair of white high heels.\n" +
                    "Her hair was done up in a bun and she had a pair of sunglasses perched on top of her head.</p>", "<p>>>Opening</p><p>Opening the door, she saw the man she'd been chasing.\n" +
                    "He was still wearing the same clothes, but he was no longer bleeding.</p>"],
                    A: ["<p>>>A</p><p>He has been playing the drums since 1994 and last played here in the Fall of 2015.</p>", "<p>>>A</p><p>The internet is a pretty funny place. So much so, in fact, that your tweets are getting you into Twitter.</p>", "<p>>>A</p><p>This movie is set in the same world as the movie in which the film takes place, and it is narrated by the director. If this is considered a bad movie, then it is also a good movie.</p>"],
                    Trust: ["<p>>>Trust</p><p>Trust me, I'm not trying to be a jerk.\n" +
                    "I'm just trying to make sure you're safe.</p>", "<p>>>Trust</p><p>Trust me, I know.\n" +
                    "I've been there.</p>", "<p>>>Trust</p><p>Trust me, I know that is not the case.</p>",]
                },
            }
        },
        methods: {
            select(myInput) {
                this.theInput = myInput
                let mySelection = this.selection
                let anArray = mySelection[myInput]
                let footerArr = this.footerArray
                const randomElement = anArray[Math.floor(Math.random() * anArray.length)];
                const randomFooter = footerArr[Math.floor(Math.random() * footerArr.length)];
    
                if (this.inputs >= 7) {
                    this.inputs = 0;
                    this.result = [];
                }
                
                this.footerWord = randomFooter
                this.result.push(randomElement)
                this.inputs++
            }
        }
    }
</script>

<style scoped lang="scss">

    .home {
        width: 90%;
        max-width: 1440px;
        margin: 0 auto;
    }
    .titleContainer {
        display: grid;
        grid-template-columns: 70% 30%;
        grid-gap: 20px;
        padding-top: 40px;
        @media only screen and (max-width: 800px) {
            grid-template-columns: 100%;
        }
    }
    
    .titleText {
        align-self: end;
        h1, p {
            margin: 0;
        }
        h1 {
            @media only screen and (max-width: 800px) {
                font-size: 28px;
            }
        }
        p {
            @media only screen and (max-width: 800px) {
                font-size: 14px;
            }
        }
    }
    
    .nodes {
        p {
            margin: 0;
            @media only screen and (max-width: 800px) {
                font-size: 14px;
            }
        }
    }
    
    .headerContainer {
        display: grid;
        grid-template-columns: 25% 50% 25%;
        font-size: 20px;
        padding: 20px 0;
        .selections {
            text-align: center;
        }
        .questions {
            text-align: right;
        }
        @media only screen and (max-width: 800px) {
            font-size: 15px;
            padding: 10px 0;
        }
    }
    

    .pageContainer {
        display: grid;
        grid-template-columns: 25% 50% 25%;
        .crashText {
            position: sticky;
            top: 50px;
            align-items: start;
            align-self: start;
            p {
                cursor: pointer;
                font-size: 18px;
                @media only screen and (max-width: 800px) {
                    font-size: 12px;
                }
            }
        }
        
        .result {
            display: flex;
            flex-direction: column;
            padding: 0 20px;
            grid-gap: 20px;
            border-right: 1px solid black;
            border-left: 1px solid black;
            .results {
                font-family: "SilkRemington", "Silk RemingtonReg", "silk_remingtonfour", "Typerighter", serif;
                font-weight: normal;
                font-size: 16px;
                display: grid;
                grid-template-columns: 20% 80%;
                grid-gap: 20px;
                @media only screen and (max-width: 800px) {
                    grid-template-columns: 100%;
                }
                img {
                    width: 100%;
                    max-width: 250px;
                    object-fit: contain;
                }
            }
        }
        


        .questions {
            display: flex;
            flex-direction: column;
            text-align: right;
            font-size: 18px;
            align-items: flex-end;
            grid-gap: 40px;
            p {
                max-width: 175px;
                margin: 0;
                @media only screen and (max-width: 800px) {
                    font-size: 14px;
                }
            }
        }
    }
    
    .footerContainer {
        position: sticky;
        bottom: 0;
        background-color: #F8F8F8;
        width: 100%;
        max-width: 1440px;
        display: grid;
        grid-template-columns: 25% 50% 25%;
        .entangled {
            text-align: center;
            @media only screen and (max-width: 800px) {
                font-size: 16px;
            }
        }
        .AI {
            text-align: right;
        }
        a {
            text-decoration: none;
            @media only screen and (max-width: 800px) {
                font-size: 12px;
            }
        }
    }
</style>

<style lang="scss">
    
    .results {
        p {
            margin: 0;
            @media only screen and (max-width: 800px) {
                font-size: 12px;
            }
        }
        img {
            width: 100%;
            height: 100%;
            max-width: 225px;
            object-fit: contain;
            @media only screen and (max-width: 800px) {
                font-size: 14px;
            }
        }
    }
</style>