<template>
    <router-view/>
</template>

<style lang="scss">
    html {
        scroll-behavior: smooth;
    }

    @font-face {
        font-family: 'Monomials';
        src:  url('assets/Monomials.ttf') format('ttf'),
        url('assets/Monomials.woff') format('woff');
    }

    @font-face {
        font-family: 'SilkRemington';
        src:  url('assets/SilkRemington-Regular.otf') format('otf'),
        url('assets/SilkRemington-Regular.ttf') format('ttf');
    }
    
    @font-face {
        font-family: 'silk_remingtonfour';
        src: url('assets/silkremington-four-webfont.woff2') format('woff2'),
        url('assets/silkremington-four-webfont.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Typerighter';
        src:  url('assets/typrighterV1-1.otf') format('otf'),
        url('assets/typrighterV1-1.ttf') format('ttf'),
        url('assets/typrighterv1-1-webfont.woff2') format('woff2'),
        url('assets/typrighterv1-1-webfont.woff') format('woff');
    }

    @font-face {
        font-family: 'Sharpie';
        src:  url('assets/subset-sharpie.ttf') format('ttf'),
        url('assets/subset-sharpie.woff') format('woff');
    }
    
    body {
        background-color: #F8F8F8;
    }
    
    #app {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #0E0E0E;
    }
    
    a {
        color: black;
    }
    
    .title {
        font-family: "SilkRemington", "Silk RemingtonReg", "silk_remingtonfour", "Typerighter", serif;
        font-weight: normal;
    }

    .human {
        font-family: Sharpie, sans-serif;
        font-weight: normal;
    }

    .AI {
        font-family: Monomials, monospace;
        font-weight: normal;
    }

    .entangled {
        font-family: "SilkRemington", "Silk RemingtonReg", "silk_remingtonfour", "Typerighter", serif;
        font-weight: normal;
    }
    
    .sign {
        text-align: right;
    }
</style>
