import {createRouter, createWebHistory} from 'vue-router'
import Home from '../pages/Home.vue'
import About from "@/pages/About";
import AIAbout from "@/pages/AIAbout";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/human-about',
        name: 'HumanAbout',
        component: About
    },
    {
        path: '/ai-about',
        name: 'AIAbout',
        component: AIAbout
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
