<template>
    <div class="about">
        <div class="titleContainer">
            <div class="titleText">
                <h1 class="title">matter combines ...</h1>
                <p><span class="human">by <a href="https://awwsmith.com" target="_blank">Andrew W. Smith</a></span> <span class="AI">and several AIs</span> <span class="entangled">for <a href="https://voidspacezine.com/" target="_blank">Voidspace's</a> Crash This Prose</span></p>
            </div>
        </div>
        <div class="aboutContainer">
            <h2 class="human" style="margin-bottom: 0;">About</h2>
            <router-link :to="{name: 'Home'}">
                <p class="human" style="margin: 0;">Return to Project</p>
            </router-link>
            <div class="aboutText">
                <p class="human">Development...Design...Curation...</p>
                <p class="human">This project is a collaborative work with a variety of artificial intelligences I did not create. Beginning with <code>&lt;&lt;Crash This Prose&gt;&gt;</code> which provided 14 unique words, I began generating hundreds of images and thousands of lines of text based on each of the 14 words using several AI generators. I selected three images or sets of text for each of the 14 words, producing a human-curated collection of AI-generated interpretations of AI-generated text. I re-present the collection here as another form of generator, both AI and human, inviting your input to generate yet another new thing. I approach this as a reflective space to consider the relations between the human and the machine/computer/ai.</p>
                <p class="human sign"> -Andrew W. Smith</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'About',
    }
</script>

<style lang="scss" scoped>
    .about {
        width: 90%;
        max-width: 1440px;
        margin: 0 auto;
    }
    
    .titleContainer {
        padding-top: 40px;
    }
    
    .titleText {
        align-self: end;
        
        h1, p {
            margin: 0;
        }
    }
    
    .aboutContainer {
        a {
            text-decoration: none;
        }
        .aboutText {
            p {
                font-size: 20px;
            }
        }
    }
</style>
