<template>
    <div class="about">
        <div class="titleContainer">
            <div class="titleText">
                <h1 class="title">matter combines ...</h1>
                <p><span class="human">by <a href="https://awwsmith.com" target="_blank">Andrew W. Smith</a></span> <span class="AI">and several AIs</span> <span class="entangled">for <a href="https://voidspacezine.com/" target="_blank">Voidspace's</a> Crash This Prose</span></p>
            </div>
        </div>
        <div class="aboutContainer">
            <h2 class="AI">About</h2>
            <router-link :to="{name: 'Home'}">
                <p class="AI">Return to Project</p>
            </router-link>
            <img src="https://awikhigan.irlh.org/gen/wl/?id=dWhJZydcN38jF1erzKzUk9fPZCWsFxgY" alt="" style="width: 1000px; padding-bottom: 40px;">
            <div class="aboutText">
                <img src="https://awikhigan.irlh.org/gen/wl/?id=1lDJL19OaOCD5N0vFaG65mfLaKuVbUtc" alt="">
                <p class="AI">matter combines ... a natural and natural, an organic, a biological, a natural-completeness, ... a complete and simple understanding of all forms of reality as they exist.</p>
                <img src="https://awikhigan.irlh.org/gen/wl/?id=KgIq5jMQmTd0r6t0ndoZuJsKbtc55ejU" alt="">
                <p class="AI">matter combines ... with the material world, and is the cause of the material world.</p>
                <img src="https://awikhigan.irlh.org/gen/wl/?id=ZBGJRdJ7ONBPrUkqZnrb07GiRlfLb06W" alt="">
                <p class="AI sign"> -Crash This Prose, DeepAI, Inferkit, Hotpot.AI, ruDALL-E</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AIAbout"
    }
</script>

<style lang="scss" scoped>
    .about {
        width: 90%;
        max-width: 1440px;
        margin: 0 auto;
    }
    
    .titleContainer {
        padding-top: 40px;
    }
    
    .titleText {
        align-self: end;
        
        h1, p {
            margin: 0;
        }
    }

    .aboutContainer {
        a {
            text-decoration: none;
        }
        .aboutText {
            img {
                width: 200px;
                height: 200px;
            }
            p {
                font-size: 18px;
            }
        }
    }
</style>